import { api } from "../helpers";

export const getCategories = (params, token) =>
	api("/articles/categories", "GET", undefined, params, token);

export const createCategory = (payload) => api("/categories", "POST", payload);

export const deleteCategory = (id) =>
	api("/categories/" + id, "DELETE", undefined);

export const createArticle = (payload, token) =>
	api("/articles", "POST", payload, undefined, token);

export const updateArticle = ({ id, ...payload }, token) =>
	api("/articles/" + id, "PUT", payload, undefined, token);

export const getArticles = (params) =>
	api("/articles", "GET", undefined, params);

export const getArticle = (id) =>
	api("/articles/" + id, "GET", undefined, undefined);

export const updateStatus = ({ id, ...payload }, token) =>
	api("/articles/" + id + "/status", "PUT", payload, undefined, token);

export const uploadImage = ({ id, image }) => {
	const formData = new FormData();
	formData.append("image", image);

	return api(
		"/articles/" + id + "/cover_image",
		"POST",
		formData,
		undefined,
		"",
		""
	);
};
