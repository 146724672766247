import { createEpic } from "../helpers";
import {
	getSettingAsync,
	updateDeliverySettingsAsync,
	updateMinAmountCartAsync,
	updateNotifyLowStockAsync,
	updateRestockTimeAsync,
	updatePeakDaySurchargeAsync,
	updateCurrentlyTrendingAsync,
	getCurrentlyTrendingAsync,
	updateFreeDeliveryAsync,
	getPopularItemsAsync,
	updatePopularItemsAsync,
} from "./settingsActions";

export const getSettingEpic = createEpic(
	getSettingAsync,
	"settings",
	"getOne",
	true
);

export const updateDeliverySettingsEpic = createEpic(
	updateDeliverySettingsAsync,
	"settings",
	"updateDeliverySettings",
	true
);

export const updateRestockTimeEpic = createEpic(
	updateRestockTimeAsync,
	"settings",
	"updateRestockTime",
	true
);

export const updateMinAmountCartEpic = createEpic(
	updateMinAmountCartAsync,
	"settings",
	"updateMinAmountCart",
	true
);

export const updateNotifyLowStockEpic = createEpic(
	updateNotifyLowStockAsync,
	"settings",
	"updateNotifyLowStock",
	true
);

export const updatePeakDaySurchargeEpic = createEpic(
	updatePeakDaySurchargeAsync,
	"settings",
	"updatePeakDaySurcharge",
	true
);

export const updateCurrentlyTrendingEpic = createEpic(
	updateCurrentlyTrendingAsync,
	"settings",
	"updateCurrentlyTrending",
	true
);

export const updateFreeDeliveryEpic = createEpic(
	updateFreeDeliveryAsync,
	"settings",
	"updateFreeDelivery",
	true
);

export const getCurrentlyTrendingEpic = createEpic(
	getCurrentlyTrendingAsync,
	"settings",
	"getCurrentlyTrending",
	false
);

export const getPopularItemsEpic = createEpic(
	getPopularItemsAsync,
	"settings",
	"getPopularItems",
	false
);

export const updatePopularItemsEpic = createEpic(
	updatePopularItemsAsync,
	"settings",
	"updatePopularItems",
	true
);
