import { createAsyncAction } from "../helpers";

export const getPromoCodesAsync = createAsyncAction("GET_PROMO_CODES");
export const getPromoCodeAsync = createAsyncAction("GET_PROMO_CODE");
export const createPromoCodeAsync = createAsyncAction("CREATE_PROMO_CODE");
export const updatePromoCodeAsync = createAsyncAction("UPDATE_PROMO_CODE");
export const updatePromoCodeStatusAsync = createAsyncAction(
	"UPDATE_PROMO_CODE_STATUS"
);

export const getPromoMultiCodeAsync = createAsyncAction(
	"GET_MULTI_PROMO_CODES"
);

export const getTagsPromoCodeAsync = createAsyncAction("GET_TAGS_PROMO_CODE");
