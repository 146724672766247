import { createEpic } from "../helpers";
import {
	createAnnouncementAsync,
	getAnnouncementAsync,
	getAnnouncementsAsync,
	updateAnnouncementAsync,
	updateAnnouncementStatusAsync,
} from "./announcementsActions";

export const listEpic = createEpic(
	getAnnouncementsAsync,
	"announcements",
	"getList",
	true
);

export const detailEpic = createEpic(
	getAnnouncementAsync,
	"announcements",
	"getDetail",
	true
);

export const addEpic = createEpic(
	createAnnouncementAsync,
	"announcements",
	"create",
	true
);

export const updateEpic = createEpic(
	updateAnnouncementAsync,
	"announcements",
	"update",
	true
);

export const updateStatusEpic = createEpic(
	updateAnnouncementStatusAsync,
	"announcements",
	"updateStatus",
	true
);
