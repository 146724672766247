import {
	authLoginAsyncAction,
	changePasswordAsyncAction,
	getDashboardAsyncAction,
	getProfileAsyncAction,
} from "./authActions";
import { createEpic } from "../helpers";

export const authLoginEpic = createEpic(
	authLoginAsyncAction,
	"auth",
	"doLogin"
);

export const getProfileEpic = createEpic(
	getProfileAsyncAction,
	"auth",
	"getProfile",
	true
);

export const getDashboardEpic = createEpic(
	getDashboardAsyncAction,
	"auth",
	"getDashboard",
	true
);

export const changePasswordEpic = createEpic(
	changePasswordAsyncAction,
	"auth",
	"changePassword",
	true
);
