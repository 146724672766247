import { combineActions, handleActions } from "redux-actions";
import {
	getCurrentlyTrendingAsync,
	getPopularItemsAsync,
	getSettingAsync,
	updateCurrentlyTrendingAsync,
	updateDeliverySettingsAsync,
	updateFreeDeliveryAsync,
	updateMinAmountCartAsync,
	updateNotifyLowStockAsync,
	updatePeakDaySurchargeAsync,
} from "./settingsActions";

const initialState = {
	list: {
		docs: [],
		page: 0,
		totalPages: 0,
	},

	detail: {
		deliverySettings: {
			blackoutDates: [],
		},
	},

	currentlyTrending: [],

	popularItems: [],

	fetch: {
		loading: false,
		error: "",
	},

	action: {
		loading: false,
		success: false,
		error: "",
	},
};

export const settingsReducer = handleActions(
	new Map([
		[
			getSettingAsync.success,
			(state, { payload }) => ({
				...state,
				detail: payload,
			}),
		],
		[
			combineActions(
				updateDeliverySettingsAsync.success,
				updateMinAmountCartAsync.success,
				updateNotifyLowStockAsync.success,
				updatePeakDaySurchargeAsync.success,
				updateCurrentlyTrendingAsync.success,
				updateFreeDeliveryAsync.success
			),
			(state, { payload }) => ({
				...state,
				action: {
					...initialState.action,
					success: payload,
				},
			}),
		],
		[
			getCurrentlyTrendingAsync.success,
			(state, { payload }) => ({ ...state, currentlyTrending: payload }),
		],
		[
			getPopularItemsAsync.success,
			(state, { payload }) => ({ ...state, popularItems: payload }),
		],
	]),
	initialState
);
