import { createEpic } from "../helpers";
import {
	getAboutUsAsync,
	getContentsAsync,
	getFAQAsync,
	getFAQCakecareAsync,
	getFeaturedOnAsync,
	getHomeCarouselAsync,
	getOurClientsAsync,
	getOurStoryAsync,
	getPrivacyPolicyAsync,
	getRefundPolicyAsync,
	getTNCAsync,
	updateAboutUsAsync,
	updateFAQAsync,
	updateFAQCakecareAsync,
	updateFeaturedOnAsync,
	updateHomeCarouselAsync,
	updateOurClientsAsync,
	updateOurStoryAsync,
	updatePrivancyPolicyAsync,
	updateRefundPolicyAsync,
	updateTNCAsync,
} from "./contentsActions";

export const getListEpic = createEpic(
	getContentsAsync,
	"contents",
	"getList",
	true
);

export const getHomeCarousel = createEpic(
	getHomeCarouselAsync,
	"contents",
	"getHomeCarousel",
	true
);

export const getFeaturedOn = createEpic(
	getFeaturedOnAsync,
	"contents",
	"getFeaturedOn",
	true
);

export const getOurClients = createEpic(
	getOurClientsAsync,
	"contents",
	"getOurClients",
	true
);

export const getRefundPolicy = createEpic(
	getRefundPolicyAsync,
	"contents",
	"getRefundPolicy",
	true
);
export const getPrivacyPolicy = createEpic(
	getPrivacyPolicyAsync,
	"contents",
	"getPrivacyPolicy",
	true
);
export const getTNC = createEpic(getTNCAsync, "contents", "getTNC", true);

export const updateHomeCarousel = createEpic(
	updateHomeCarouselAsync,
	"contents",
	"updateHomeCarousel",
	true
);

export const updateFeaturedOn = createEpic(
	updateFeaturedOnAsync,
	"contents",
	"updateFeaturedOn",
	true
);

export const updateOurClients = createEpic(
	updateOurClientsAsync,
	"contents",
	"updateOurClients",
	true
);

export const updateRefundPolicy = createEpic(
	updateRefundPolicyAsync,
	"contents",
	"updateRefundPolicy",
	true
);
export const updatePrivacyPolicy = createEpic(
	updatePrivancyPolicyAsync,
	"contents",
	"updatePrivacyPolicy",
	true
);
export const updateTNC = createEpic(
	updateTNCAsync,
	"contents",
	"updateTNC",
	true
);

export const getFAQ = createEpic(getFAQAsync, "contents", "getFAQ", true);
export const updateFAQ = createEpic(
	updateFAQAsync,
	"contents",
	"updateFAQ",
	true
);

export const getFAQCarecare = createEpic(
	getFAQCakecareAsync,
	"contents",
	"getFAQCakecare",
	true
);
export const updateFAQCakecare = createEpic(
	updateFAQCakecareAsync,
	"contents",
	"updateFAQCakecare",
	true
);

export const getAboutUs = createEpic(
	getAboutUsAsync,
	"contents",
	"getAboutUs",
	true
);
export const updateAboutUsEpic = createEpic(
	updateAboutUsAsync,
	"contents",
	"updateAboutUs",
	true
);

export const getOurStoryEpic = createEpic(
	getOurStoryAsync,
	"contents",
	"getOurStory"
);
export const updateOurStoryEpic = createEpic(
	updateOurStoryAsync,
	"contents",
	"updateOurStory",
	true
);
