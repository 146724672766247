import { handleActions } from "redux-actions";
import { getSubscribersAsync } from "./subscribersActions";

const initialState = {
	list: {
		docs: [],
		page: 0,
		totalPages: 0,
	},

	detail: {},

	fetch: {
		loading: false,
		error: "",
	},

	action: {
		loading: false,
		success: false,
		error: "",
	},
};

export const subscribersReducer = handleActions(
	new Map([
		[
			getSubscribersAsync.success,
			(state, { payload }) => ({
				...state,
				list: payload,
			}),
		],
	]),
	initialState
);
