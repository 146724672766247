import { api } from "../helpers";

export const getList = (params, token) =>
	api("/subscribers", "GET", undefined, params, token);

export const exportExcel = (payload, token) =>
	api("/subscribers/export", "POST", payload, undefined, token, "blob");

export const destory = (id, token) =>
	api("/subscribers/" + id, "DELETE", undefined, undefined, token);
