import { combineActions, handleActions } from "redux-actions";
import {
	getAboutUsAsync,
	getContentsAsync,
	getFAQAsync,
	getFAQCakecareAsync,
	getFeaturedOnAsync,
	getHomeCarouselAsync,
	getOurClientsAsync,
	getOurStoryAsync,
	getPrivacyPolicyAsync,
	getRefundPolicyAsync,
	getTNCAsync,
	updateAboutUsAsync,
	updateFAQAsync,
	updateFAQCakecareAsync,
	updateFeaturedOnAsync,
	updateHomeCarouselAsync,
	updateOurClientsAsync,
	updateOurStoryAsync,
	updatePrivancyPolicyAsync,
	updateRefundPolicyAsync,
	updateTNCAsync,
} from "./contentsActions";

const initialState = {
	list: [],

	homeCarousel: {
		images: [],
	},

	featuredOn: {
		data: [],
	},

	ourClients: {
		data: [],
	},

	faq: {
		data: [],
	},

	faqCakecare: {
		data: [],
	},

	refundPolicy: "",
	privacyPolicy: "",
	tnc: "",
	aboutUs: "",

	ourStory: {
		image: "",
		description: "",
	},

	action: {
		loading: false,
		success: false,
		error: "",
	},
};

export const contentsReducer = handleActions(
	new Map([
		[
			getContentsAsync.success,
			(state, { payload }) => ({ ...state, list: payload }),
		],
		[
			getHomeCarouselAsync.success,
			(state, { payload }) => ({ ...state, homeCarousel: payload }),
		],
		[
			getFeaturedOnAsync.success,
			(state, { payload }) => ({ ...state, featuredOn: payload }),
		],
		[
			getOurClientsAsync.success,
			(state, { payload }) => ({ ...state, ourClients: payload }),
		],
		[
			getRefundPolicyAsync.success,
			(state, { payload }) => ({ ...state, refundPolicy: payload }),
		],
		[
			getPrivacyPolicyAsync.success,
			(state, { payload }) => ({ ...state, privacyPolicy: payload }),
		],
		[
			getTNCAsync.success,
			(state, { payload }) => ({ ...state, tnc: payload }),
		],
		[
			getFAQAsync.success,
			(state, { payload }) => ({ ...state, faq: payload }),
		],
		[
			getFAQCakecareAsync.success,
			(state, { payload }) => ({ ...state, faqCakecare: payload }),
		],
		[
			getAboutUsAsync.success,
			(state, { payload }) => ({ ...state, aboutUs: payload }),
		],
		[
			getOurStoryAsync.success,
			(state, { payload }) => ({ ...state, ourStory: payload }),
		],
		[
			combineActions(
				updateHomeCarouselAsync.success,
				updateFeaturedOnAsync.success,
				updateOurClientsAsync.success,
				updateRefundPolicyAsync.success,
				updatePrivancyPolicyAsync.success,
				updateTNCAsync.success,
				updateFAQAsync.success,
				updateFAQCakecareAsync.success,
				updateAboutUsAsync.success,
				updateOurStoryAsync.success
			),
			(state, { payload }) => ({
				...state,
				action: { ...initialState.action, success: payload },
			}),
		],
	]),
	initialState
);
