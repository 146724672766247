import { combineActions, handleActions } from "redux-actions";
import {
	createOrderAsync,
	getOrdersAsync,
	getOrderAsync,
	updateOrderAsync,
	updateOrderStatusAsync,
	cancelOrderAsync,
	refundOrderAsync,
	getOrderTagsAsync,
	getOrderDashboardAsync,
	getLatestOrderAsync,
} from "./ordersActions";

const initialState = {
	list: {
		docs: [],
		page: 0,
		totalPages: 0,
	},

	latest: {},

	detail: {},

	dashboard: [],

	tags: [],

	fetch: {
		loading: false,
		error: "",
	},

	action: {
		loading: false,
		success: false,
		error: "",
	},
};

export const ordersReducer = handleActions(
	new Map([
		[
			getOrdersAsync.success,
			(state, { payload }) => ({
				...state,
				list: payload,
			}),
		],
		[
			getOrderAsync.success,
			(state, { payload }) => ({
				...state,
				detail: payload,
			}),
		],
		[
			getOrderDashboardAsync.success,
			(state, { payload }) => ({
				...state,
				dashboard: payload,
			}),
		],
		[
			getOrderTagsAsync.success,
			(state, { payload }) => ({ ...state, tags: payload }),
		],
		[
			getLatestOrderAsync.success,
			(state, { payload }) => ({ ...state, latest: payload }),
		],
		[
			combineActions(updateOrderStatusAsync.request),
			(state) => ({
				...state,
				action: {
					...initialState.action,
				},
			}),
		],
		[
			combineActions(
				createOrderAsync.success,
				updateOrderStatusAsync.success,
				updateOrderAsync.success,
				cancelOrderAsync.success,
				refundOrderAsync.success
			),
			(state, { payload }) => ({
				...state,
				action: {
					...initialState.action,
					success: payload,
				},
			}),
		],
		[
			combineActions(createOrderAsync.failure, updateOrderAsync.failure),
			(state, { payload }) => ({
				...state,
				action: {
					...initialState.action,
					error: payload,
				},
			}),
		],
	]),
	initialState
);
