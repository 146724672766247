import * as auth from "./auth/auth.service";
import * as articles from "./articles/articles.service";
import * as categories from "./categories/categories.service";
import * as settings from "./settings/settings.service";
import * as products from "./products/products.service";
import * as delivery_methods from "./delivery_methods/delivery_methods.service";
import * as outskirts from "./outskirts/outskirts.service";
import * as delivery_zones from "./delivery_zones/delivery_zones.service";
import * as orders from "./orders/orders.service";
import * as contents from "./contents/contents.service";
import * as customers from "./customers/customers.service";
import * as promo_codes from "./promo_codes/promo_codes.service";
import * as announcements from "./announcements/announcements.service";
import * as subscribers from "./subscribers/subscribers.service";
import * as admins from "./admins/admins.service";
import * as contact_us from "./contact_us/contact_us.service";
import { api } from "./helpers";

import { ajax } from "rxjs/ajax";

/**
 * VERCEL_GIT_COMMIT_REF: git branch of the commit
 */
export const API_URL =
	process.env.REACT_APP_GIT_BRANCH === "master"
		? process.env.REACT_APP_API_URL
		: process.env.REACT_APP_API_STAGING_URL;

const uploadImage = (payload, token) => {
	const data = new FormData();

	payload.forEach((file) => {
		data.append("images", file);
	});

	return ajax({
		url: API_URL + "/images_upload",
		method: "POST",
		headers: {
			Authorization: "Bearer " + token,
		},
		body: data,
	});
};

const removeImages = (payload, token) =>
	api("/images_remove", "DELETE", { images: payload }, null, token);

export default {
	auth,
	admins,
	articles,
	categories,
	settings,
	products,
	delivery_methods,
	outskirts,
	delivery_zones,
	orders,
	contents,
	customers,
	promo_codes,
	announcements,
	subscribers,
	contact_us,
	uploadImage,
	removeImages,
};
