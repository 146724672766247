import { combineActions, handleActions } from "redux-actions";
import {
	createPromoCodeAsync,
	getPromoCodeAsync,
	getPromoCodesAsync,
	getPromoMultiCodeAsync,
	getTagsPromoCodeAsync,
	updatePromoCodeAsync,
} from "./promoCodesActions";

const initialState = {
	list: {
		docs: [],
		page: 0,
		totalPages: 0,
	},

	detail: {},

	detailWithCodes: {
		codes: [],
	},

	tags: [],

	fetch: {
		loading: false,
		error: "",
	},

	action: {
		loading: false,
		success: false,
		error: "",
	},
};

export const promoCodesReducer = handleActions(
	new Map([
		[
			getPromoCodesAsync.success,
			(state, { payload }) => ({
				...state,
				list: payload,
			}),
		],
		[
			getPromoCodeAsync.success,
			(state, { payload }) => ({
				...state,
				detail: payload,
			}),
		],
		[
			getTagsPromoCodeAsync.success,
			(state, { payload }) => ({ ...state, tags: payload }),
		],
		[
			getPromoMultiCodeAsync.success,
			(state, { payload }) => ({ ...state, detailWithCodes: payload }),
		],
		[
			combineActions(
				createPromoCodeAsync.success,
				updatePromoCodeAsync.success
			),
			(state, { payload }) => ({
				...state,
				action: {
					...initialState.action,
					success: payload,
				},
			}),
		],
		[
			combineActions(
				createPromoCodeAsync.failure,
				updatePromoCodeAsync.failure
			),
			(state, { payload }) => ({
				...state,
				action: {
					...initialState.action,
					error: payload,
				},
			}),
		],
	]),
	initialState
);
