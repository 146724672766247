import { createEpic } from "../helpers";
import {
	createCustomerAsync,
	getCustomerAsync,
	getCustomersAsync,
	getCustomerTagsAsync,
	updateCustomerAsync,
	updateCustomerStatusAsync,
} from "./customersActions";

export const getListEpic = createEpic(
	getCustomersAsync,
	"customers",
	"getList",
	true
);

export const getDetailEpic = createEpic(
	getCustomerAsync,
	"customers",
	"getDetail",
	true
);

export const createCustomerEpic = createEpic(
	createCustomerAsync,
	"customers",
	"create",
	true
);

export const updateEpic = createEpic(
	updateCustomerAsync,
	"customers",
	"update",
	true
);

export const updateStatusEpic = createEpic(
	updateCustomerStatusAsync,
	"customers",
	"updateStatus",
	true
);

export const getTagsEpic = createEpic(
	getCustomerTagsAsync,
	"customers",
	"getTags",
	true
);
