import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import moment from "moment-timezone";
// import { renderRoutes } from 'react-router-config';

import { store, persistor } from "./store/store";

import "./App.scss";

const loading = () => (
	<div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Login/Login"));
const ForgetPassword = React.lazy(() =>
	import("./views/ForgetPassword/ForgetPassword")
);
const ResetPassword = React.lazy(() =>
	import("./views/ResetPassword/ResetPassword")
);

class App extends Component {
	componentDidMount() {
		moment.tz.setDefault("Asia/Singapore");
	}

	render() {
		return (
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<HashRouter>
						<React.Suspense fallback={loading()}>
							<Switch>
								<Route
									exact
									path="/login"
									name="Login Page"
									render={(props) => <Login {...props} />}
								/>
								<Route
									exact
									path="/forget-password"
									name="Forget Password"
									render={(props) => (
										<ForgetPassword {...props} />
									)}
								/>
								<Route
									exact
									path="/reset-password"
									name="Reset Password"
									render={(props) => (
										<ResetPassword {...props} />
									)}
								/>
								<Route
									path="/"
									name="Home"
									render={(props) => (
										<DefaultLayout {...props} />
									)}
								/>
							</Switch>
						</React.Suspense>
					</HashRouter>
				</PersistGate>
			</Provider>
		);
	}
}

export default App;
