import { createAction } from "redux-actions";
import { createAsyncAction } from "../helpers";

export const authLoginAsyncAction = createAsyncAction("AUTH_LOGIN");

export const getProfileAsyncAction = createAsyncAction("GET_PROFILE");
export const getDashboardAsyncAction = createAsyncAction("GET_DASHBOARD");

export const changePasswordAsyncAction = createAsyncAction("CHANGE_PASSWORD");

export const authLogoutAction = createAction("AUTH_LOGOUT");
