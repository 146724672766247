import { createAsyncAction } from "../helpers";

export const getSettingAsync = createAsyncAction("GET_SETTING");

export const updateDeliverySettingsAsync = createAsyncAction(
	"UPDATE_DELIVERY_SETTINGS"
);

export const updateRestockTimeAsync = createAsyncAction("UPDATE_RESTOCK_TIME");

export const updateMinAmountCartAsync = createAsyncAction(
	"UPDATE_MIN_AMOUNT_CART"
);

export const updateNotifyLowStockAsync = createAsyncAction(
	"UPDATE_NOTIFY_LOW_STOCK"
);

export const updatePeakDaySurchargeAsync = createAsyncAction(
	"UPDATE_PEAK_DAY_SURCHARGE"
);

export const updateCurrentlyTrendingAsync = createAsyncAction(
	"UPDATE_CURRENTLY_TRENDING"
);

export const getCurrentlyTrendingAsync = createAsyncAction(
	"GET_CURRENTLY_TRENDING"
);

export const updatePopularItemsAsync = createAsyncAction(
	"UPDATE_POPULAR_ITEMS"
);

export const getPopularItemsAsync = createAsyncAction("GET_POPULAR_ITEMS");

export const updateFreeDeliveryAsync = createAsyncAction(
	"UPDATE_FREE_DELIVERY"
);
