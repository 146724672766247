import { api } from "../helpers";

export const getList = (params) => api("/outskirts", "GET", undefined, params);

export const getDetail = (id) =>
	api("/outskirts/" + id, "GET", undefined, undefined);

export const create = (payload, token) =>
	api("/outskirts", "POST", payload, undefined, token);

export const update = ({ id, ...payload }, token) =>
	api("/outskirts/" + id, "PUT", payload, undefined, token);

export const updateStatus = ({ id, ...payload }, token) =>
	api("/outskirts/" + id + "/status", "PUT", payload, undefined, token);
