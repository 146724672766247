import { createEpic } from "../helpers";
import {
	createOutskirtAsync,
	updateOutskirtAsync,
	getOutskirtsAsync,
	updateOutskirtStatusAsync,
	getOutskirtAsync,
} from "./outskirtsActions";

export const createDeliveryMethodEpic = createEpic(
	createOutskirtAsync,
	"outskirts",
	"create",
	true
);

export const updateEpic = createEpic(
	updateOutskirtAsync,
	"outskirts",
	"update",
	true
);

export const getListEpic = createEpic(
	getOutskirtsAsync,
	"outskirts",
	"getList",
	true
);

export const updateStatusEpic = createEpic(
	updateOutskirtStatusAsync,
	"outskirts",
	"updateStatus",
	true
);

export const getDetailEpic = createEpic(
	getOutskirtAsync,
	"outskirts",
	"getDetail",
	true
);
