import { compose, combineReducers, createStore, applyMiddleware } from "redux";
import { createEpicMiddleware, combineEpics } from "redux-observable";
import { persistStore } from "redux-persist";
import logger from "redux-logger";

import services from "../services";

import { authReducer } from "./auth/authReducer";
import * as authEpics from "./auth/authEpics";

import { articlesReducer } from "./articles/articlesReducer";
import * as articlesEpics from "./articles/articlesEpics";

import { categoriesReducer } from "./categories/categoriesReducer";
import * as categoriesEpics from "./categories/categoriesEpics";

import { settingsReducer } from "./settings/settingsReducer";
import * as settingsEpics from "./settings/settingsEpics";

import { productsReducer } from "./products/productsReducer";
import * as productsEpics from "./products/productsEpics";

import { deliveryMethodsReducer } from "./delivery_methods/deliveryMethodsReducer";
import * as deliveryMethodsEpics from "./delivery_methods/deliveryMethodsEpics";

import { outskirtsReducer } from "./outskirts/outskirtsReducer";
import * as outskirtsEpics from "./outskirts/outskirtsEpics";

import { deliveryZonesReducer } from "./delivery_zones/deliveryZonesReducer";
import * as deliveryZonesEpics from "./delivery_zones/deliveryZonesEpics";

import { ordersReducer } from "./orders/ordersReducer";
import * as ordersEpics from "./orders/ordersEpics";

import { contentsReducer } from "./contents/contentsReducer";
import * as contentsEpics from "./contents/contentsEpics";

import { customersReducer } from "./customers/customersReducer";
import * as customersEpics from "./customers/customersEpics";

import { promoCodesReducer } from "./promo_codes/promoCodesReducer";
import * as promoCodesEpics from "./promo_codes/promoCodesEpics";

import { announcementsReducer } from "./announcements/announcementsReducer";
import * as announcementsEpics from "./announcements/announcementsEpics";

import { subscribersReducer } from "./subscribers/subscribersReducer";
import * as subscribersEpics from "./subscribers/subscribersEpics";

import { adminsReducer } from "./admins/adminsReducer";
import * as adminsEpics from "./admins/adminsEpics";

import { contactUsReducer } from "./contact_us/contactUsReducer";
import * as contactUsEpics from "./contact_us/contactUsEpics";

let composeEnhancers;
if (process.env.NODE_ENV === "production") {
	composeEnhancers = compose;
} else {
	composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const epicMiddleware = createEpicMiddleware({
	dependencies: services,
});

const rootReducer = combineReducers({
	auth: authReducer,
	articles: articlesReducer,
	categories: categoriesReducer,
	settings: settingsReducer,
	products: productsReducer,
	delivery_methods: deliveryMethodsReducer,
	outskirts: outskirtsReducer,
	delivery_zones: deliveryZonesReducer,
	orders: ordersReducer,
	contents: contentsReducer,
	customers: customersReducer,
	promo_codes: promoCodesReducer,
	announcements: announcementsReducer,
	subscribers: subscribersReducer,
	admins: adminsReducer,
	contact_us: contactUsReducer,
});

const rootEpic = combineEpics(
	...Object.values(authEpics),
	...Object.values(articlesEpics),
	...Object.values(categoriesEpics),
	...Object.values(settingsEpics),
	...Object.values(productsEpics),
	...Object.values(deliveryMethodsEpics),
	...Object.values(outskirtsEpics),
	...Object.values(deliveryZonesEpics),
	...Object.values(ordersEpics),
	...Object.values(contentsEpics),
	...Object.values(customersEpics),
	...Object.values(promoCodesEpics),
	...Object.values(announcementsEpics),
	...Object.values(subscribersEpics),
	...Object.values(adminsEpics),
	...Object.values(contactUsEpics)
);

export const store = createStore(
	rootReducer,
	process.env.NODE_ENV === "production"
		? composeEnhancers(applyMiddleware(epicMiddleware))
		: composeEnhancers(applyMiddleware(logger, epicMiddleware))
);

export const persistor = persistStore(store);

epicMiddleware.run(rootEpic);
