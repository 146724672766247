import { createEpic } from "../helpers";
import {
	createAdminAsync,
	getAdminAsync,
	getAdminsAsync,
	updateAdminAsync,
	updateAdminStatusAsync,
} from "./adminsActions";

export const getListEpic = createEpic(
	getAdminsAsync,
	"admins",
	"getList",
	true
);

export const getDetailEpic = createEpic(
	getAdminAsync,
	"admins",
	"getDetail",
	true
);

export const addEpic = createEpic(createAdminAsync, "admins", "create", true);
export const updateEpic = createEpic(
	updateAdminAsync,
	"admins",
	"update",
	true
);

export const updateStatusEpic = createEpic(
	updateAdminStatusAsync,
	"admins",
	"updateStatus",
	true
);
