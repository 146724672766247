import { api } from "../helpers";

export const getList = (params) => api("/orders", "GET", undefined, params);

export const getLatest = (params, token) =>
	api("/orders/latest", "GET", undefined, params, token);

export const getDashboard = (params, token) =>
	api("/orders/dashboard", "GET", undefined, params, token);

export const getDetail = (id) =>
	api("/orders/" + id, "GET", undefined, undefined);

export const create = (payload, token) =>
	api("/orders/adhoc", "POST", payload, undefined, token);

export const update = ({ id, ...payload }, token) =>
	api("/orders/" + id + "/admin", "PUT", payload, undefined, token);

export const updateInfo = ({ id, ...payload }, token) =>
	api("/orders/" + id + "/info", "PUT", payload, undefined, token);

export const getTags = (_, token) =>
	api("/orders/tags", "GET", undefined, undefined, token);

export const updateStatus = (payload, token) =>
	api("/orders/status", "PUT", payload, undefined, token);

export const cancel = ({ id, ...payload }, token) =>
	api(`/orders/${id}/cancelled`, "POST", payload, undefined, token);

export const refund = ({ id, ...payload }, token) =>
	api(`/orders/${id}/refund`, "POST", payload, undefined, token);

export const resend = (id, token) =>
	api(`/orders/${id}/resend`, "POST", undefined, undefined, token);

export const exportExcel = (payload, token) =>
	api("/orders/export", "POST", payload, undefined, token, "blob");

export const exportPackingSlip = (payload, token) =>
	api("/orders/packing_slip", "POST", payload, undefined, token, "blob");

export const exportCustomExcel = (payload, token) =>
	api("/orders/custom_export", "POST", undefined, payload, token, "blob");
