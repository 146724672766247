import { createAsyncAction } from "../helpers";

export const getCategoriesAsync = createAsyncAction("GET_CATEGORIES");

export const getCategoryAsync = createAsyncAction("GET_CATEGORY");

export const createCategoryAsync = createAsyncAction("CREATE_CATEGORY");
export const updateCategoryAsync = createAsyncAction("UPDATE_CATEGORY");
export const updateCategoryStatusAsync = createAsyncAction(
	"UPDATE_CATEGORY_STATUS"
);
