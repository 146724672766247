import { createAsyncAction } from "../helpers";

export const createOrderAsync = createAsyncAction("CREATE_ORDER");
export const updateOrderAsync = createAsyncAction("UPDATE_ORDER");
export const cancelOrderAsync = createAsyncAction("CANCEL_ORDER");
export const refundOrderAsync = createAsyncAction("REFUND_ORDER");
export const updateOrderInfoAsync = createAsyncAction("UPDATE_ORDER_INFO");

export const resendOrderEmailAsync = createAsyncAction("RESEND_ORDER_EMAIL");

export const getOrdersAsync = createAsyncAction("GET_ORDERS");
export const getLatestOrderAsync = createAsyncAction("GET_LATEST_ORDER");

export const getOrderAsync = createAsyncAction("GET_ORDER");

export const getOrderTagsAsync = createAsyncAction("GET_ORDER_TAGS");

export const updateOrderStatusAsync = createAsyncAction("UPDATE_ORDER_STATUS");

export const getOrderDashboardAsync = createAsyncAction("GET_ORDER_DASHBOARD");
