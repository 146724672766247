import { ajax } from "rxjs/ajax";
import { isEmpty } from "lodash";
import { stringify } from "qs";

/**
 * VERCEL_GIT_COMMIT_REF: git branch of the commit
 */
export const API_URL =
	process.env.REACT_APP_GIT_BRANCH === "master"
		? process.env.REACT_APP_API_URL
		: process.env.REACT_APP_API_STAGING_URL;

export const api = (
	path,
	method = "GET",
	payload,
	params,
	token = "",
	header = "json"
) => {
	const options = {
		url: API_URL + path,
		method: method.toUpperCase(),
		headers: {},
	};

	if (!isEmpty(params)) {
		options["url"] =
			options["url"] + stringify(params, { addQueryPrefix: true });
	}

	if (payload) {
		options["body"] = payload;
	}

	if (token) {
		options["headers"]["Authorization"] = "Bearer " + token;
	}

	options["headers"]["Content-Type"] = "application/json";

	if (header === "blob") {
		options["responseType"] = "blob";
	}

	return ajax(options);
};
