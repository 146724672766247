import { createAsyncAction } from "../helpers";

export const createDeliveryZoneAsync = createAsyncAction(
	"CREATE_DELIVERY_ZONE"
);
export const updateDeliveryZoneAsync = createAsyncAction(
	"UPDATE_DELIVERY_ZONE"
);

export const getDeliveryZonesAsync = createAsyncAction("GET_DELIVERY_ZONES");
export const getDeliveryZoneAsync = createAsyncAction("GET_DELIVERY_ZONE");

export const updateDeliveryZoneStatusAsync = createAsyncAction(
	"UPDATE_DELIVERY_ZONE_STATUS"
);
