import { handleActions } from "redux-actions";
import { REHYDRATE, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
	authLoginAsyncAction,
	authLogoutAction,
	changePasswordAsyncAction,
	getDashboardAsyncAction,
	getProfileAsyncAction,
} from "./authActions";

const initialState = {
	authenticated: true,
	token: "",
	profile: {},
	login: {
		loading: false,
		error: "",
	},
	dashboard: {
		registeredCustomers: 0,
		totalOrdersOfToday: 0,
		totalRevenueOfToday: 0,
		totalOrders: 0,
		totalRevenue: 0,

		dailyRevenue: [],
		monthlyRevenue: [],
		yearlyRevenue: [],

		dailyOrders: [],
		monthlyOrders: [],
		yearlyOrders: [],
	},
	changePassword: {
		success: false,
		error: "",
	},
};

export const authReducer = persistReducer(
	{
		key: "auth",
		storage,
		whitelist: ["token"],
	},
	handleActions(
		new Map([
			[
				REHYDRATE,
				(state, action) => ({
					...state,
					authenticated:
						action.payload && action.payload.token ? true : false,
				}),
			],
			[
				authLoginAsyncAction.request,
				(state) => ({
					...state,
					login: { ...initialState.login },
				}),
			],
			[
				authLoginAsyncAction.success,
				(state, { payload }) => ({
					...state,
					token: payload.accessToken,
					authenticated: true,
				}),
			],
			[
				authLoginAsyncAction.failure,
				(state, { payload }) => ({
					...state,
					login: {
						loading: false,
						error: payload,
					},
				}),
			],
			[
				authLogoutAction,
				(state) => ({
					...state,
					token: "",
					profile: {},
					authenticated: false,
				}),
			],
			[
				getProfileAsyncAction.success,
				(state, { payload }) => ({ ...state, profile: payload }),
			],
			[
				changePasswordAsyncAction.failure,
				(state, { payload }) => ({
					...state,
					changePassword: {
						...initialState.changePassword,
						error: payload,
					},
				}),
			],
			[
				changePasswordAsyncAction.success,
				(state) => ({
					...state,
					changePassword: {
						...initialState.changePassword,
						success: true,
					},
				}),
			],
			[
				getDashboardAsyncAction.success,
				(state, { payload }) => ({ ...state, dashboard: payload }),
			],
		]),
		initialState
	)
);
