import { handleActions } from "redux-actions";
import { getContactUsAsync } from "./contactUsActions";

const initialState = {
	list: {
		docs: [],
		page: 0,
		totalPages: 0,
	},

	detail: {},

	fetch: {
		loading: false,
		error: "",
	},

	action: {
		loading: false,
		success: false,
		error: "",
	},
};

export const contactUsReducer = handleActions(
	new Map([
		[
			getContactUsAsync.success,
			(state, { payload }) => ({ ...state, list: payload }),
		],
	]),
	initialState
);
