import { api } from "../helpers";

export const getList = (params, token) =>
	api("/promo_codes", "GET", undefined, params, token);

export const getDetail = (id, token) =>
	api("/promo_codes/" + id, "GET", undefined, undefined, token);

export const getTags = (_, token) =>
	api("/promo_codes/tags", "GET", undefined, undefined, token);

export const getCodes = (id, token) =>
	api(`/promo_codes/${id}/codes`, "GET", undefined, undefined, token);

export const create = (payload, token) =>
	api("/promo_codes", "POST", payload, undefined, token);

export const update = ({ id, ...payload }, token) =>
	api("/promo_codes/" + id, "PUT", payload, undefined, token);

export const updateStatus = ({ id, ...payload }, token) =>
	api(`/promo_codes/${id}/status`, "PUT", payload, undefined, token);

export const check = (payload, token) =>
	api("/promo_codes/check", "POST", payload, undefined, token);

export const exportCodes = (id, token) =>
	api(
		`/promo_codes/${id}/codes/export`,
		"POST",
		undefined,
		undefined,
		token,
		"blob"
	);
