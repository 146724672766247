import { api } from "../helpers";

export const doLogin = (payload) =>
	api("/auth/login", "POST", { ...payload, type: "ADMIN" });

export const getProfile = (_, token) =>
	api("/auth/me", "GET", undefined, undefined, token);

export const getDashboard = (_, token) =>
	api("/dashboard", "GET", undefined, undefined, token);

export const changePassword = (payload, token) =>
	api("/auth/me/change_password", "PUT", payload, undefined, token);

export const forgetPassword = (payload) =>
	api("/auth/send-reset-password/admin", "POST", payload, undefined);

export const resetPassword = (payload) =>
	api("/auth/reset-password", "POST", payload, undefined);
