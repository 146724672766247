import { handleActions, combineActions } from "redux-actions";

import {
	createDeliveryZoneAsync,
	updateDeliveryZoneAsync,
	getDeliveryZonesAsync,
	getDeliveryZoneAsync,
} from "./deliveryZonesActions";

const initialState = {
	list: {
		docs: [],
		page: 0,
		totalPages: 0,
	},

	detail: {},

	fetch: {
		loading: false,
		error: "",
	},

	action: {
		loading: false,
		success: false,
		error: "",
	},
};

export const deliveryZonesReducer = handleActions(
	new Map([
		[
			combineActions(
				createDeliveryZoneAsync.success,
				updateDeliveryZoneAsync.success
			),
			(state, { payload }) => ({
				...state,
				action: { ...initialState.action, success: payload },
			}),
		],
		[
			getDeliveryZonesAsync.success,
			(state, { payload }) => ({
				...state,
				list: payload,
			}),
		],
		[
			getDeliveryZoneAsync.success,
			(state, { payload }) => ({ ...state, detail: payload }),
		],
	]),
	initialState
);
