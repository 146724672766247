import { api } from "../helpers";

export const getList = (params) =>
	api("/delivery_methods", "GET", undefined, params);

export const getDetail = (id) =>
	api("/delivery_methods/" + id, "GET", undefined, undefined);

export const create = (payload, token) =>
	api("/delivery_methods", "POST", payload, undefined, token);

export const update = ({ id, ...payload }, token) =>
	api("/delivery_methods/" + id, "PUT", payload, undefined, token);

export const updateStatus = ({ id, ...payload }, token) =>
	api(
		"/delivery_methods/" + id + "/status",
		"PUT",
		payload,
		undefined,
		token
	);
