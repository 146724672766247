import { api } from "../helpers";

export const getList = (params, token) =>
	api("/contents", "GET", undefined, params, token);

export const getHomeCarousel = () => api("/contents/home_carousel", "GET");

export const updateHomeCarousel = (payload, token) =>
	api("/contents/home_carousel", "PUT", payload, undefined, token);

export const updateFeaturedOn = (payload, token) =>
	api("/contents/featured_on", "PUT", payload, undefined, token);
export const getFeaturedOn = () => api("/contents/featured_on", "GET");

export const getOurClients = () => api("/contents/our_clients", "GET");
export const updateOurClients = (payload, token) =>
	api("/contents/our_clients", "PUT", payload, undefined, token);

export const getRefundPolicy = () => api("/contents/refund_policy", "GET");
export const updateRefundPolicy = (payload, token) =>
	api("/contents/refund_policy", "PUT", payload, undefined, token);

export const getPrivacyPolicy = () => api("/contents/privacy_policy", "GET");
export const updatePrivacyPolicy = (payload, token) =>
	api("/contents/privacy_policy", "PUT", payload, undefined, token);

export const getTNC = () => api("/contents/tnc", "GET");
export const updateTNC = (payload, token) =>
	api("/contents/tnc", "PUT", payload, undefined, token);

export const getFAQ = () => api("/contents/faq", "GET");
export const updateFAQ = (payload, token) =>
	api("/contents/faq", "PUT", payload, undefined, token);

export const getFAQCakecare = () => api("/contents/faq_cakecare", "GET");
export const updateFAQCakecare = (payload, token) =>
	api("/contents/faq_cakecare", "PUT", payload, undefined, token);

export const getAboutUs = () => api("/contents/about_us", "GET");
export const updateAboutUs = (payload, token) =>
	api("/contents/about_us", "PUT", payload, undefined, token);

export const getOurStory = () => api("/contents/our_story", "GET");
export const updateOurStory = (payload, token) =>
	api("/contents/our_story", "PUT", payload, undefined, token);
