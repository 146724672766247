import { createEpic } from "../helpers";
import {
	createProductAsync,
	getProductsAsync,
	getProductAsync,
	updateProductAsync,
	updateProductStatusAsync,
	updateProductStocksAsync,
	getProductStocksAsync,
	getNotifyLowStocksAsync,
	updateNotifyLowStockAsync,
	getProductTagsAsync,
	updateFixedStocksAsync,
	duplicateProductAsync,
	getProductSoldAsync,
} from "./productsActions";

export const createProductEpic = createEpic(
	createProductAsync,
	"products",
	"create",
	true
);

export const updateProductEpic = createEpic(
	updateProductAsync,
	"products",
	"update",
	true
);

export const getProductsEpic = createEpic(
	getProductsAsync,
	"products",
	"getList",
	true
);

export const getProductEpic = createEpic(
	getProductAsync,
	"products",
	"getDetail",
	true
);

export const updateProductStatusEpic = createEpic(
	updateProductStatusAsync,
	"products",
	"updateStatus",
	true
);

export const updateProductStocksEpic = createEpic(
	updateProductStocksAsync,
	"products",
	"updateStocks",
	true
);

export const getStocksEpic = createEpic(
	getProductStocksAsync,
	"products",
	"getStocks",
	true
);

export const getSoldEpic = createEpic(
	getProductSoldAsync,
	"products",
	"getSold",
	true
);

export const getNotifyLowStocksEpic = createEpic(
	getNotifyLowStocksAsync,
	"products",
	"getNotifyLowStock",
	true
);

export const updateNotifyLowStocksEpic = createEpic(
	updateNotifyLowStockAsync,
	"products",
	"updateNotifyLowStock",
	true
);

export const updateFixedStocksEpic = createEpic(
	updateFixedStocksAsync,
	"products",
	"updateFixedStocks",
	true
);

export const getTagsEpic = createEpic(
	getProductTagsAsync,
	"products",
	"getTags",
	true
);

export const duplicateEpic = createEpic(
	duplicateProductAsync,
	"products",
	"duplicate",
	true
);
