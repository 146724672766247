import { createEpic } from "../helpers";
import {
	getArticleCategoriesAsync,
	createArticleCategoryAsync,
	deleteArticleCategoryAsync,
	createArticleAsync,
	getArticlesAsync,
	getArticleAsync,
	updateArticleAsync,
	uploadArticleCoverImageAsync,
	updateArticleStatusAsync,
} from "./articlesActions";

export const getArticleCategoriesEpic = createEpic(
	getArticleCategoriesAsync,
	"articles",
	"getCategories",
	true
);

export const createArticleCategoryEpic = createEpic(
	createArticleCategoryAsync,
	"articles",
	"createCategory"
);

export const deleteArticleCategoryEpic = createEpic(
	deleteArticleCategoryAsync,
	"articles",
	"deleteCategory"
);

export const createArticleEpic = createEpic(
	createArticleAsync,
	"articles",
	"createArticle",
	true
);

export const updateArticleStatusEpic = createEpic(
	updateArticleStatusAsync,
	"articles",
	"updateStatus",
	true
);

export const getArticlesEpic = createEpic(
	getArticlesAsync,
	"articles",
	"getArticles"
);

export const getArticleEpic = createEpic(
	getArticleAsync,
	"articles",
	"getArticle"
);

export const updateArticleEpic = createEpic(
	updateArticleAsync,
	"articles",
	"updateArticle",
	true
);

export const uploadArticleCoverImageEpic = createEpic(
	uploadArticleCoverImageAsync,
	"articles",
	"uploadImage"
);
