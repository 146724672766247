import { api } from "../helpers";

export const getList = (params) => api("/products", "GET", undefined, params);

export const getListBySlug = (slug) =>
	api(`/categories/${slug}/slug/products`, "GET", undefined, undefined);

export const getDetail = (id) =>
	api("/products/" + id, "GET", undefined, undefined);

export const getStocks = (id, token) =>
	api(`/products/${id}/stocks`, "GET", undefined, undefined, token);

export const getSold = (id, token) =>
	api(`/products/${id}/sold`, "GET", undefined, undefined, token);

export const create = (payload, token) =>
	api("/products", "POST", payload, undefined, token);

export const update = ({ id, ...payload }, token) =>
	api("/products/" + id, "PUT", payload, undefined, token);

export const updateStatus = ({ id, ...payload }, token) =>
	api("/products/" + id + "/status", "PUT", payload, undefined, token);

export const updateStocks = ({ id, ...payload }, token) =>
	api("/products/" + id + "/stocks", "PUT", payload, undefined, token);

export const updateFixedStocks = ({ id, ...payload }, token) =>
	api("/products/" + id + "/fixed_stocks", "PUT", payload, undefined, token);

export const getTags = (_, token) =>
	api("/products/tags", "GET", undefined, undefined, token);

export const getNotifyLowStock = (_, token) =>
	api("/stocks/notify_low_stock", "GET", undefined, undefined, token);

export const updateNotifyLowStock = ({ _id, ...payload }, token) =>
	api("/stocks/" + _id, "PUT", payload, undefined, token);

export const duplicate = (id, token) =>
	api("/products/" + id + "/duplicate", "POST", undefined, undefined, token);
