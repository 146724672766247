import { createEpic } from "../helpers";
import {
	createPromoCodeAsync,
	getPromoCodeAsync,
	getPromoCodesAsync,
	getPromoMultiCodeAsync,
	getTagsPromoCodeAsync,
	updatePromoCodeAsync,
	updatePromoCodeStatusAsync,
} from "./promoCodesActions";

export const listEpic = createEpic(
	getPromoCodesAsync,
	"promo_codes",
	"getList",
	true
);

export const detailEpic = createEpic(
	getPromoCodeAsync,
	"promo_codes",
	"getDetail",
	true
);

export const addEpic = createEpic(
	createPromoCodeAsync,
	"promo_codes",
	"create",
	true
);

export const updateEpic = createEpic(
	updatePromoCodeAsync,
	"promo_codes",
	"update",
	true
);

export const updateStatusEpic = createEpic(
	updatePromoCodeStatusAsync,
	"promo_codes",
	"updateStatus",
	true
);

export const getTagsEpic = createEpic(
	getTagsPromoCodeAsync,
	"promo_codes",
	"getTags",
	true
);

export const getCodesEpic = createEpic(
	getPromoMultiCodeAsync,
	"promo_codes",
	"getCodes",
	true
);
