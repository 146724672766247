import { createAsyncAction } from "../helpers";

export const createDeliveryMethodAsync = createAsyncAction(
	"CREATE_DELIVERY_METHOD"
);
export const updateDeliveryMethodAsync = createAsyncAction(
	"UPDATE_DELIVERY_METHOD"
);

export const getDeliveryMethodsAsync = createAsyncAction(
	"GET_DELIVERY_METHODS"
);
export const getDeliveryMethodAsync = createAsyncAction("GET_DELIVERY_METHOD");

export const updateDeliveryMethodStatusAsync = createAsyncAction(
	"UPDATE_DELIVERY_METHOD_STATUS"
);
