import { createAsyncAction } from "../helpers";

export const getProductsAsync = createAsyncAction("GET_PRODUCTS");
export const getProductAsync = createAsyncAction("GET_PRODUCT");
export const getProductSoldAsync = createAsyncAction("GET_PRODUCT_SOLD");

export const createProductAsync = createAsyncAction("CREATE_Product");
export const updateProductAsync = createAsyncAction("UPDATE_PRODUCT");
export const updateProductStatusAsync = createAsyncAction(
	"UPDATE_PRODUCT_STATUS"
);
export const updateProductStocksAsync = createAsyncAction(
	"UPDATE_PRODUCT_STOCKS"
);

export const duplicateProductAsync = createAsyncAction("DUPLICATE_PRODUCT");

export const getProductStocksAsync = createAsyncAction("GET_PRODUCT_STOCKS");
export const getNotifyLowStocksAsync = createAsyncAction(
	"GET_NOTIFY_LOW_STOCKS"
);

export const updateNotifyLowStockAsync = createAsyncAction(
	"UPDATE_NOTIFY_LOW_STOCKS"
);

export const updateFixedStocksAsync = createAsyncAction("UPDATE_FIXED_STOCKS");

export const getProductTagsAsync = createAsyncAction("GET_PRODUCT_TAGS");
