import { createAsyncAction } from "../helpers";

export const createOutskirtAsync = createAsyncAction("CREATE_OUTSKIRT");
export const updateOutskirtAsync = createAsyncAction("UPDATE_OUTSKIRT");

export const getOutskirtsAsync = createAsyncAction("GET_OUTSKIRTS");
export const getOutskirtAsync = createAsyncAction("GET_OUTSKIRT");

export const updateOutskirtStatusAsync = createAsyncAction(
	"UPDATE_OUTSKIRT_STATUS"
);
