import { createEpic } from "../helpers";
import {
	createDeliveryZoneAsync,
	updateDeliveryZoneAsync,
	getDeliveryZonesAsync,
	updateDeliveryZoneStatusAsync,
	getDeliveryZoneAsync,
} from "./deliveryZonesActions";

export const createDeliveryMethodEpic = createEpic(
	createDeliveryZoneAsync,
	"delivery_zones",
	"create",
	true
);

export const updateEpic = createEpic(
	updateDeliveryZoneAsync,
	"delivery_zones",
	"update",
	true
);

export const getListEpic = createEpic(
	getDeliveryZonesAsync,
	"delivery_zones",
	"getList",
	true
);

export const updateStatusEpic = createEpic(
	updateDeliveryZoneStatusAsync,
	"delivery_zones",
	"updateStatus",
	true
);

export const getDetailEpic = createEpic(
	getDeliveryZoneAsync,
	"delivery_zones",
	"getDetail",
	true
);
