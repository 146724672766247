import { createEpic } from "../helpers";
import {
	createCategoryAsync,
	getCategoriesAsync,
	getCategoryAsync,
	updateCategoryAsync,
	updateCategoryStatusAsync,
} from "./categoriesActions";

export const getCategoriesEpic = createEpic(
	getCategoriesAsync,
	"categories",
	"getList",
	true
);

export const createCategoryEpic = createEpic(
	createCategoryAsync,
	"categories",
	"create",
	true
);

export const updateCategoryEpic = createEpic(
	updateCategoryAsync,
	"categories",
	"update",
	true
);

export const getCategoryEpic = createEpic(
	getCategoryAsync,
	"categories",
	"getDetail",
	true
);

export const updateCategoryStatusEpic = createEpic(
	updateCategoryStatusAsync,
	"categories",
	"updateStatus",
	true
);
