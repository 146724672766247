import { createEpic } from "../helpers";
import {
	createDeliveryMethodAsync,
	getDeliveryMethodsAsync,
	updateDeliveryMethodStatusAsync,
	getDeliveryMethodAsync,
	updateDeliveryMethodAsync,
} from "./deliveryMethodsActions";

export const createDeliveryMethodEpic = createEpic(
	createDeliveryMethodAsync,
	"delivery_methods",
	"create",
	true
);

export const updateEpic = createEpic(
	updateDeliveryMethodAsync,
	"delivery_methods",
	"update",
	true
);

export const getDeliveryMethodsEpic = createEpic(
	getDeliveryMethodsAsync,
	"delivery_methods",
	"getList",
	true
);

export const updateDeliveryMethodStatusEpic = createEpic(
	updateDeliveryMethodStatusAsync,
	"delivery_methods",
	"updateStatus",
	true
);

export const getDeliveryMethodEpic = createEpic(
	getDeliveryMethodAsync,
	"delivery_methods",
	"getDetail",
	true
);
