import { createAsyncAction } from "../helpers";

export const getCustomersAsync = createAsyncAction("GET_CUSTOMERS");

export const getCustomerAsync = createAsyncAction("GET_CUSTOMER");

export const createCustomerAsync = createAsyncAction("CREATE_CUSTOMER");
export const updateCustomerAsync = createAsyncAction("UPDATE_CUSTOMER");

export const updateCustomerStatusAsync = createAsyncAction(
	"UPDATE_CUSTOMER_STATUS"
);

export const getCustomerTagsAsync = createAsyncAction("GET_CUSTOMER_TAGS");
