import { createAsyncAction } from "../helpers";

export const getContentsAsync = createAsyncAction("GET_CONTENTS");

export const getHomeCarouselAsync = createAsyncAction("GET_HOME_CAROUSEL");
export const updateHomeCarouselAsync = createAsyncAction(
	"UPDATE_HOME_CAROUSEL"
);

export const updateFeaturedOnAsync = createAsyncAction("UPDATE_FEATURED_ON");
export const getFeaturedOnAsync = createAsyncAction("GET_FEATURED_ON");

export const getOurClientsAsync = createAsyncAction("GET_OUR_CLIENTS");
export const updateOurClientsAsync = createAsyncAction("UPDATE_OUR_CLIENTS");

export const getRefundPolicyAsync = createAsyncAction("GET_REFUND_POLICY");
export const updateRefundPolicyAsync = createAsyncAction(
	"UPDATE_REFUND_POLICY"
);

export const getPrivacyPolicyAsync = createAsyncAction("GET_PRIVACY_POLICY");
export const updatePrivancyPolicyAsync = createAsyncAction(
	"UPDATE_PRIVACY_POLICY"
);

export const getTNCAsync = createAsyncAction("GET_TNC");
export const updateTNCAsync = createAsyncAction("UPDATE_TNC");

export const getFAQAsync = createAsyncAction("GET_FAQ");
export const updateFAQAsync = createAsyncAction("UPDATE_FAQ");

export const getFAQCakecareAsync = createAsyncAction("GET_FAQ_CAKECARE");
export const updateFAQCakecareAsync = createAsyncAction("UPDATE_FAQ_CAKECARE");

export const getAboutUsAsync = createAsyncAction("GET_ABOUT_US");
export const updateAboutUsAsync = createAsyncAction("UPDATE_ABOUT_US");

export const getOurStoryAsync = createAsyncAction("GET_OUR_STORY");
export const updateOurStoryAsync = createAsyncAction("UPDATE_OUR_STORY");
