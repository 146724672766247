import { api } from "../helpers";

export const getList = (params, token) =>
	api("/announcements", "GET", undefined, params, token);

export const getDetail = (id, token) =>
	api("/announcements/" + id, "GET", undefined, undefined, token);

export const create = (payload, token) =>
	api("/announcements", "POST", payload, undefined, token);

export const update = ({ id, ...payload }, token) =>
	api("/announcements/" + id, "PUT", payload, undefined, token);

export const updateStatus = ({ id, ...payload }, token) =>
	api(`/announcements/${id}/status`, "PUT", payload, undefined, token);
