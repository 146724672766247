import { createAsyncAction } from "../helpers";

export const getArticleCategoriesAsync = createAsyncAction(
	"GET_ARTICLE_CATEGORIES"
);

export const createArticleCategoryAsync = createAsyncAction(
	"CREATE_ARTICLE_CATEGORY"
);

export const deleteArticleCategoryAsync = createAsyncAction(
	"DELETE_ARTICLE_CATEGORY"
);

export const createArticleAsync = createAsyncAction("CREATE_ARTICLE");
export const updateArticleAsync = createAsyncAction("UPDATE_ARTICLE");
export const uploadArticleCoverImageAsync = createAsyncAction(
	"UPLOAD_ARTICLE_COVER_IMAGE"
);
export const updateArticleStatusAsync = createAsyncAction(
	"UPDATE_ARTICLE_STATUS"
);

export const getArticlesAsync = createAsyncAction("GET_ARTICLES");

export const getArticleAsync = createAsyncAction("GET_ARTICLE");
