import { createEpic } from "../helpers";

import {
	cancelOrderAsync,
	createOrderAsync,
	getOrderAsync,
	getOrdersAsync,
	getOrderTagsAsync,
	refundOrderAsync,
	updateOrderAsync,
	updateOrderInfoAsync,
	updateOrderStatusAsync,
	resendOrderEmailAsync,
	getOrderDashboardAsync,
	getLatestOrderAsync,
} from "./ordersActions";

export const createrEpic = createEpic(
	createOrderAsync,
	"orders",
	"create",
	true
);

export const updateEpic = createEpic(
	updateOrderAsync,
	"orders",
	"update",
	true
);

export const cancelEpic = createEpic(
	cancelOrderAsync,
	"orders",
	"cancel",
	true
);

export const refundEpic = createEpic(
	refundOrderAsync,
	"orders",
	"refund",
	true
);

export const updateInfoEpic = createEpic(
	updateOrderInfoAsync,
	"orders",
	"updateInfo",
	true
);

export const getOrdersEpic = createEpic(
	getOrdersAsync,
	"orders",
	"getList",
	true
);

export const getDashboardEpic = createEpic(
	getOrderDashboardAsync,
	"orders",
	"getDashboard",
	true
);

export const getLatestEpic = createEpic(
	getLatestOrderAsync,
	"orders",
	"getLatest",
	true
);

export const getOrderEpic = createEpic(
	getOrderAsync,
	"orders",
	"getDetail",
	true
);

export const resendOrderEmailEpic = createEpic(
	resendOrderEmailAsync,
	"orders",
	"resend",
	true
);

export const updateOrderStatusEpic = createEpic(
	updateOrderStatusAsync,
	"orders",
	"updateStatus",
	true
);

export const getTagsEpic = createEpic(
	getOrderTagsAsync,
	"orders",
	"getTags",
	true
);
