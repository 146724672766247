import { handleActions, combineActions } from "redux-actions";

import {
	createCategoryAsync,
	getCategoriesAsync,
	getCategoryAsync,
	updateCategoryAsync,
} from "./categoriesActions";

const initialState = {
	list: {
		docs: [],
		page: 0,
		totalPages: 0,
	},

	detail: {},

	fetch: {
		loading: false,
		error: "",
	},

	action: {
		loading: false,
		success: false,
		error: "",
	},
};

export const categoriesReducer = handleActions(
	new Map([
		[
			getCategoriesAsync.success,
			(state, { payload }) => ({
				...state,
				list: payload,
			}),
		],
		[
			getCategoryAsync.success,
			(state, { payload }) => ({
				...state,
				detail: payload,
			}),
		],
		[
			combineActions(
				createCategoryAsync.success,
				updateCategoryAsync.success
			),
			(state, { payload }) => ({
				...state,
				action: {
					...initialState.action,
					success: payload,
				},
			}),
		],
	]),
	initialState
);
