import { api } from "../helpers";

export const getOne = () => api("/settings", "GET");

export const updateDeliverySettings = (payload, token) =>
	api("/settings/delivery", "PUT", payload, undefined, token);

export const updateRestockTime = (payload, token) =>
	api("/settings/restock_time", "PUT", payload, undefined, token);

export const updateMinAmountCart = (payload, token) =>
	api("/settings/min_amount_cart", "PUT", payload, undefined, token);

export const updateNotifyLowStock = (payload, token) =>
	api("/settings/notify_low_stock", "PUT", payload, undefined, token);

export const updatePeakDaySurcharge = (payload, token) =>
	api("/settings/peak_day_surcharge", "PUT", payload, undefined, token);

export const updateCurrentlyTrending = (payload, token) =>
	api("/settings/currently_trending", "PUT", payload, undefined, token);

export const updateFreeDelivery = (payload, token) =>
	api("/settings/free_delivery", "PUT", payload, undefined, token);

export const getCurrentlyTrending = () =>
	api("/settings/currently_trending", "GET", undefined, undefined);

export const getPopularItems = () =>
	api("/settings/popular_items", "GET", undefined, undefined);

export const updatePopularItems = (payload, token) =>
	api("/settings/popular_items", "PUT", payload, undefined, token);
