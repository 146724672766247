import { combineActions, handleActions } from "redux-actions";
import {
	createProductAsync,
	getProductsAsync,
	getProductAsync,
	updateProductAsync,
	getProductStocksAsync,
	getNotifyLowStocksAsync,
	updateNotifyLowStockAsync,
	getProductTagsAsync,
	updateProductStocksAsync,
	updateFixedStocksAsync,
	duplicateProductAsync,
	getProductSoldAsync,
} from "./productsActions";

const initialState = {
	list: {
		docs: [],
		page: 0,
		totalPages: 0,
	},

	detail: {},

	stocks: [],

	sold: [],

	tags: [],

	duplicate: false,

	notifyLowStocks: [],

	fetch: {
		loading: false,
		error: "",
	},

	action: {
		loading: false,
		success: false,
		error: "",
	},
};

export const productsReducer = handleActions(
	new Map([
		[
			combineActions(
				createProductAsync.success,
				updateProductAsync.success,
				updateNotifyLowStockAsync.success,
				updateProductStocksAsync.success,
				updateFixedStocksAsync.success
			),
			(state, { payload }) => ({
				...state,
				action: { ...initialState.action, success: payload },
			}),
		],
		[
			getProductsAsync.success,
			(state, { payload }) => ({
				...state,
				list: payload,
			}),
		],
		[
			getProductTagsAsync.success,
			(state, { payload }) => ({ ...state, tags: payload }),
		],
		[
			getProductAsync.success,
			(state, { payload }) => ({ ...state, detail: payload }),
		],
		[
			getProductStocksAsync.success,
			(state, { payload }) => ({ ...state, stocks: payload }),
		],
		[
			getNotifyLowStocksAsync.success,
			(state, { payload }) => ({ ...state, notifyLowStocks: payload }),
		],
		[
			duplicateProductAsync.request,
			(state) => ({ ...state, duplicate: false }),
		],
		[
			duplicateProductAsync.success,
			(state, { payload }) => ({ ...state, duplicate: payload }),
		],
		[
			getProductSoldAsync.success,
			(state, { payload }) => ({ ...state, sold: payload }),
		],
	]),
	initialState
);
