import { handleActions, combineActions } from "redux-actions";

import {
	createDeliveryMethodAsync,
	getDeliveryMethodsAsync,
	getDeliveryMethodAsync,
	updateDeliveryMethodAsync,
} from "./deliveryMethodsActions";

const initialState = {
	list: {
		docs: [],
		page: 0,
		totalPages: 0,
	},

	detail: {},

	fetch: {
		loading: false,
		error: "",
	},

	action: {
		loading: false,
		success: false,
		error: "",
	},
};

export const deliveryMethodsReducer = handleActions(
	new Map([
		[
			combineActions(
				createDeliveryMethodAsync.success,
				updateDeliveryMethodAsync.success
			),
			(state, { payload }) => ({
				...state,
				action: { ...initialState.action, success: payload },
			}),
		],
		[
			getDeliveryMethodsAsync.success,
			(state, { payload }) => ({
				...state,
				list: payload,
			}),
		],
		[
			getDeliveryMethodAsync.success,
			(state, { payload }) => ({ ...state, detail: payload }),
		],
	]),
	initialState
);
