import { api } from "../helpers";

export const getList = (params, token) =>
	api("/customers", "GET", undefined, params, token);

export const getDetail = (id, token) =>
	api(`/customers/${id}`, "GET", undefined, undefined, token);

export const updateStatus = ({ id, ...payload }, token) =>
	api(`/customers/${id}/status`, "PUT", payload, undefined, token);

export const update = ({ id, ...payload }, token) =>
	api(`/customers/${id}`, "PUT", payload, undefined, token);

export const create = (payload, token) =>
	api(`/customers`, "POST", payload, undefined, token);

export const sendResetPassword = ({ id }, token) =>
	api(`/customers/${id}/reset_password`, "POST", undefined, undefined, token);

export const getTags = (_, token) =>
	api("/customers/tags", "GET", undefined, undefined, token);

export const destroy = (id, token) =>
	api("/customers/" + id, "DELETE", undefined, undefined, token);
